import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { HydroPage_Home, Prototype1, Prototype2, Prototype3 } from "../HydroLab";
import { Mark_CV } from "../CV";

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path="/" component={HydroPage_Home } />
                        <Route exact path="/hydroponics/p1" component={Prototype1} />
                        <Route exact path="/hydroponics/p2" component={Prototype2} />
                        <Route exact path="/hydroponics/p3" component={Prototype3} />
                        <Route exact path="/cv" component={ Mark_CV } />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export { App };