import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { Footer, Navigation } from "../_components";

function HydroPage_Home() {
    return (
        <div className="HydroPageV1">
            <Navigation />
            <main className="page project-page">
                <section className="portfolio-block project">
                    <div className="container">
                        <div className="heading">
                            <h2>Remote Urban Hydroponics System (RUHS)</h2>
                        </div>
                        <div className="image" style={{ backgroundImage: "url(https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/stockimgs/hydroponic-farm-blog-1030x651.png)" }}></div>
                        <div className="row">
                            <div className="col-12 col-md-6 offset-md-1 info">
                                <h3>Overview</h3>
                                <p>As we move into the future, our agricultural supply chains are going to faces continued challenges when it comes to the environment, and human health.  These challenges can range from environmental impact, to the unpredictability of a changing climate, to population growth.  Each of these challenges can pose a significant risk to our agricultural supply chain.</p>
                                <p>In order to combat these challenges, we will need to continue to find more innovative solutions to these problems.  One solution is to increase our use of hydroponics and indoor farming and irrigation solutions on a more industrial scale.  Hydroponics is one way to provides us with a more environmentally friendly way of producing crops.  Through the use of hydroponics, we can mitigate the effects that climate change may have on crop growing seasons.  Hydroponics can also help us to greatly reduce our dependencies on harmful herbicides and pesticides that are currently being used to cultivate our crops.   In addition, Hydroponics can help make more efficient use of our water supply, with the added benefit of having little to no impact on our vulnerable waterways.</p>
                                <p>As an added benefit, hydroponics can also change how we deliver agricultural produce to our urban populations.  Through the user of hydroponics, we can more cost effectively grow crops indoors, all year round, and much closer to our end consumers.  This essentially has the benefit of removing the transportation cost to deliver fresh agriculture into our cities by growing it within the cities themselves.</p>
                                <p>The Remote Urban Hydroponics System (RUHS) is my attempt to prototype out as many of the computer control systems needed to aid in urban hydroponic farming, so it can be managed on a more industrial scale.</p>
                                <h6>Prototype 1</h6>
                                <p>Prototype 1 was the initial proof of concept to develop automation controllers for a Hydroponics environment.  It comprised of three subsystems to manage the various components of the Hydroponics environment as well as offering a touchscreen management interface to control each subsystem.</p>
                                <h6>Prototype 2</h6>
                                <p>Prototype 2 was develop to address a lot of the operational issues that arose out of the initial trials of Prototype 1.  The biggest change that came out of prototype to was how the subsystems would be managed.  Command, Control and data visualization was moved away from the touchscreen computer and into the Cloud.  This allowed us to have remote access to the subsystems through a secure web portal.</p>
                                <h6>Prototype 3</h6>
                                <p>Prototype 3 is currently in development and its projected to be ready for operational trials by mid-August.  The Goal of prototype 3 is to start proving out the concept of a more scalable, modular, and configurable architecture.  The prototype will also attempt to fix lingering mechanical issues that came up during the operational trials of both Prototypes 1 and 2.</p>
                                <p>To accomplish these goals, we will seek to further decentralize the micro-controller subsystems and allow them to be placed closer to things that they are intended to control.  We will also be we re-designing the whole Hydroponic platform so it can become more modular in its design.  Both goals will allow us more easily to scale up systems as needed. The platform will also become more modular so that it will become more easy to swap in upgraded systems as they become available.</p>
                                <p>Finally, changes will also be made to the platforms data collection processes so that the data being transmitted from the microcontrollers can be stored in a time based format allowing us to provide the users more analytical insights into how their growing environments are performing.</p>
                                <h6>Prototype 4</h6>
                                <p>While prototype 3 is currently in development, ideas for prototype 4 are already being collected.  Some of the ideas will revolve around adding a more 'retail friendly' method for configuring the subsystems onto a customer's network.  I will also be looking at ways to improve the usability of the UI.</p>
                            </div>
                            <div className="col-12 col-md-3 offset-md-1 meta">
                                <div className="tags">
                                    <span className="meta-heading">Tags</span>
                                    <Link to="/hydroponics/p1">Prototype 1</Link>
                                    <Link to="/hydroponics/p2">Prototype 2</Link>
                                    <Link to="/hydroponics/p3">Prototype 3 (In development)</Link>
                                    <span className="meta-heading">Date</span><span>09-06-2020 to Present</span>
                                </div>
                            </div>
                        </div>
                        <div className="more-projects">
                            <h3 className="text-center">Photo Gallary</h3>
                            <div className="row gallery">
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/IMG_0121.JPG">
                                            <img class="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/IMG_0121.JPG"></img>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/RWAF2403.JPG">
                                            <img className="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/RWAF2403.JPG"></img>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/TNCI0718.JPG">
                                            <img className="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/TNCI0718.JPG"></img>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3">
                                    <div class="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/KJRF0192.JPG">
                                            <img class="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/KJRF0192.JPG"></img>
                                        </a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default withRouter(HydroPage_Home);