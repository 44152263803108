// JavaScript source code
import React from 'react';
import { Link, withRouter } from "react-router-dom";

function Footer() {
    return (
        <footer className="page-footer">
            <div className="container">
                <div className="links">
                    <Link to="/cv">About me</Link>
                    <Link to="/">Projects</Link>
                </div>
                <div className="social-icons">
                    
                </div>
            </div>
        </footer>
    );
}

export default Footer;