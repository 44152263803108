// JavaScript source code
import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { Footer, Navigation } from "../_components";

function Prototype2() {
    return (
        <div className="Prototype1">
            <Navigation />
            <main className="page project-page">
                <section className="portfolio-block project">
                    <div className="container">
                        <div className="heading">
                            <h2>Remote Urban Hydroponics System (RUHS) Prototype 2</h2>
                        </div>
                        <div className="image" style={{ backgroundImage: "url(https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/stockimgs/hydroponic-farm-blog-1030x651.png)" }}></div>
                        <div className="row">
                            <div className="col-12 col-md-6 offset-md-1 info">
                                <h3>Overview</h3>
                                <p>Prototype 2 introduces a radical departure for Prototype 1 and it is where we start putting the Remote into RUHS.</p>
                                <p>After the development of and operational testing of the original Prototype it was decided that the system needed to move away from the use of a touch screen UI and towards a web-based system that could be hosted in the cloud.</p>
                                <p>The touch screen, while cool, was extremely limited in the amount of data that could be displayed to a user.  It also limited the options on where we could place the computer systems relative to the hardware that they were controlling.  It also meant that the user had to go to the hydroponic rack to check on the environment and make any settings changes that would be needed.  By removing the touch screen and moving all of the sub-systems controls into the cloud we could simplify the hardware architecture while giving our users a superior user interface experience.</p>
                                <p>To accomplish this move, all three of the original sub-systems would need to be redeveloped to utilize the HTTP communication protocol over a secure socket layer.  The User Interface would have to be redeveloped into a simpler user-friendly interface that could be hosted in the cloud.  Endpoints would also need to be developed so that both the Web UI and the micro controller sub-systems could send and receive data from a common web hosted platform. </p>
                                <p>In addition to rebuilding the micro controller into a more IoT friendly architecture, a major system upgrade was also made to the Hydroponic Solution controller so it could integrate and communicate with the Atlas Scientific EZO sensor technology suite.</p>
                                <h6>Moving to an IoT Architecture Pattern</h6>
                                <p>One of the fundamental problems of Prototype 1 was the need to keep the micro controllers close together so that they could be connected to the UI controller.  Because of this, the configuration of any hydroponics rack would be limited due to the distance that the micro-controllers could be placed from the UI controller.  If the microcontrollers could be untethered from the UI controller, then they could be placed in more optimal locations allowing for greater flexibility in the Hydroponic System's configuration.  Enter the Arduino Nano IoT 33, this micro-controller is a complete redesign of the original Arduino Nano and is optimized for IoT development.  Two of its main departing features is its built-in secure Wi-Fi and Bluetooth capabilities that allow the device to connect into a Local Area Network easily without having to make use of any third-party shield components.  Its other attractive feature is that it now uses the SAMD21 Cortex 32bit low power ARM processer over the original ATmega 328 processor that most Arduinos utilize.  By moving to this micro-controller, we gain freedom to reconceive how computer controlled hydroponics systems can be built, and controlled.</p>
                                <h6>Web Platform Architecture</h6>
                                <p>To allow the user to interact with the micro-controlled through a Web based UI, an information platform would need to be built to broker that interaction.  The web platform was built for this version of the Prototype on AWS (Amazon Web Services) Serverless Stack and utilized several of its core services.</p>
                                <p>The web UI was built using React JS and was hosted on AWS using the Amplify Build Management service.  Amplify significantly reduced the build and deployment process of the Web UI since it could be directly hooked into the Github repository.  The whole build and deployment process could be then triggered after a successful merge request into the deployment branch.</p>
                                <p>DynamoDB was used as the primary data repository for both the front-end web UI and the micro-controllers.  The micro-controllers would send their current state data to DynamoDB so it could be viewed in the UI.  The micro-controller would also from time to time also update their configuration states from the same database.  The UI would access DynamoDB to get last current state of the devices and present it to the user.  The Web UI would also update and stage any configuration changes that needed to be sent back to the device controllers.</p>
                                <p>All of the backend coding was done in Node JS through the use of simple Lambda Functions.</p>
                                <p>The API Gateway service managed the endpoints that were used by both the micro-controllers as well as the Web UI.  The micro-controllers would utilize a different endpoint from what the Web UI would use.  This would allow me to separate and analyze the API usage between both system clients.  It would also allow me to secure the micro-controller endpoints with an API Key and manage CORS for the UI endpoints.</p>
                                <h6>Atlas Scientific & EZO</h6>
                                <p>When maintaining the perfect growth mixture for your hydroponics environment, precise sensor measurements can mean the difference between a lush green plant and a dead one.  This is where Atlas Scientific and their EZO sensor suite comes into play.  Prototype 2 had a radical upgrade when it came to the pH and water turbidity sensors being used to regulate the hydroponic fertilizer mix being pumped throughout the hydroponic system.  Prototype 1 made use of some cheaper pH and water conductivity sensors that would fail after 3-4 weeks of continuous use within the 14-gallon hydroponic tank.  If this system was going to be viable for commercial use, then it would need some heavy-duty sensor equipment.  That is where Atlas Scientific and their EZO sensors step in.</p>
                                <p>Prototype 2 makes use of three EZO sensors, the pH sensor, the Conductivity Sensor, and the RTD Temperature sensor.  EZO works as sub systems to the main Hydroponic controller, and they all communicate with it over the I2C/two wire protocol.  When it is time for the Hydroponic controller to take a pH and Turbidity measurement it will first ping the RTD computer to get the latest water temperature for the solution in the main tank.  Once it has the water temperature, it will then pass that temperature along to both the pH system and the Conductivity system so they can calculate and return an accurate pH and EC value back to the Hydroponic solution controller.  Once It has that data the controller can then take any appropriate action in order to maintain the optimal growth mixture that a user has specified.</p>
                            </div>
                            <div className="col-12 col-md-3 offset-md-1 meta">
                                <div className="tags">
                                    <span className="meta-heading">Tags</span>
                                    <Link to="/hydroponics/p1">Prototype 1</Link>
                                    <Link to="/hydroponics/p3">Prototype 3 (In development)</Link>
                                    <a target="_blank" href="https://store.arduino.cc/usa/nano-33-iot-with-headers">Arduino Nano 33 IoT</a>
                                    <a target="_blank" href="https://atlas-scientific.com/embedded-solutions/ezo-ph-circuit/">Atlas Scientific EZO pH Circuit</a>
                                    <a target="_blank" href="https://atlas-scientific.com/embedded-solutions/ezo-conductivity-circuit/">Atlas Scientific EZO Conductivity Circuit</a>
                                    <a target="_blank" href="https://atlas-scientific.com/embedded-solutions/ezo-rtd-temperature-circuit/">Atlas Scientific EZO RTD Circuit</a>
                                    <span className="meta-heading">Date</span><span>12-04-2020 to 01-29-2021</span>
                                </div>
                            </div>
                        </div>
                        <div className="more-projects">
                            <h3 className="text-center">Photo Gallary</h3>
                            <div className="row gallery">
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/KJRF0192.JPG">
                                            <img class="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/KJRF0192.JPG"></img>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/p2Dashboard.png">
                                            <img className="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/p2Dashboard.png"></img>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/p2EvManager.png">
                                            <img className="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/p2EvManager.png"></img>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3">
                                    <div class="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/p2HyManager.png">
                                            <img class="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/p2HyManager.png"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default withRouter(Prototype2);