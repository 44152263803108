import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { Footer, Navigation } from "../_components";

function Prototype1() {
    return (
        <div className="Prototype1">
            <Navigation />
            <main className="page project-page">
                <section className="portfolio-block project">
                    <div className="container">
                        <div className="heading">
                            <h2>Remote Urban Hydroponics System (RUHS) Prototype 1</h2>
                        </div>
                        <div className="image" style={{ backgroundImage: "url(https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/stockimgs/hydroponic-farm-blog-1030x651.png)" }}></div>
                        <div className="row">
                            <div className="col-12 col-md-6 offset-md-1 info">
                                <h3>Overview</h3>
                                <p>Prototype 1 was the initial proof of concept to develop the micro-control systems that would be used to manage a hydroponics environment.  The system was broken down into four main control systems that report up to a fifth parent controller. The five nodes that make up the system are as follows: the UI controller, the Pharm Controller, the Climate Controller, and the Reservoir Management Controller.  Each of these controllers were linked together using the I2C/Two Wire method of communication.  By connecting all the sub-systems together through I2C the Main UI controller can both send out configuration data to the child nodes, or it can request a sensor update that is can then use to report back to the user through the user interface.</p>
                                <p>By breaking up the control system into these four smaller systems we can separate out the concerns, both logically in the code as well as physically in the hardware.  Each node or subsystem worked autonomously from each other and was only responsible for the parts of the system that it has been tasked with maintaining.  The benefits of doing it this way was to make it more maintainable since it is easier to swap out a single node from the control package.</p>
                                <h6>Environment & Lighting Controller:</h6>
                                <p>The role of the Environmental & Lighting Controller is to mange the Lighting and Cooling of the hydroponic rack.  The system user both a temperature and humidity sensor to collect its environmental data and then decides on whether to turn the fan systems on or off based on those sensory inputs.</p>
                                <p>The system also makes use of a photovoltaic sensor to determine the current light saturation that the hydroponic rack is being exposed to and will then adjust the auxiliary grow lights in the rack to compensate accordingly.</p>
                                <p>The Environmental & lighting control package is run off a 5.0v Arduino Nano and it is connected to the User Interface controller using the I2C/Two Wire communication protocol.  Fan control is managed by interfacing with a simple 110v Relay switch, while the lighting system is controlled through Pulse Wave Modulation to adjust the light intensity of the grow lights.</p>
                                <h6>Hydroponic Solution Controller:</h6>
                                <p>When growing plants in a hydroponic environment, the pH level of the water being used becomes particularly important since different types of vegetation grow better at different pH levels. The role of the solution controller is to maintain a user specified pH or Set-Point for the watery solution that is being pumped through the Hydroponics rack.</p>
                                <p>To maintain the correct pH level, the controller will take continuous measurements of the watery solution in the 14-gallon tank.  If the solution starts to deviate from the specified Set-Point, then controller will then attempt to take the corrective action needed to get the solution back to the specified Set-Point.  This is done by either adding more acidic or basic hydroponic fertilizer to the watery solution that is being stored in the 14-gallon tank.</p>
                                <p>The controller will add either pH Up (acid) or pH Down (base) by cycling the required dosage pump through a series of Hi time/Low time durations.  The Hi time duration is the amount of time in which the dosage pump will add solution into the tank.  The Low time duration is the amount of time that the controller will pause the dosage pump so it can reevaluate the solution mix in the tank and determine if more solution will be needed or not.  The controller will keep cycling between Hi and Low times until the solution mix in the tank has been returned to the Set-Point that was specified by the user.</p>
                                <p>The Hydroponic Solution Controller uses a 5.0v Arduino Nano to manage the pH state within the 14-gallon tank and it is connected to the User Interface controller using the I2C/Two Wire communication protocol.</p>
                                <h6>Hydroponic Reservoir Management Controller:</h6>
                                <p>The role of the Reservoir Management controller is to manage the distribution of the contents of the 14-gallon tank throughout the hydroponic system.  Its primary purpose is to monitor the amount of solution that is in the reservoir and to make sure that it is getting delivered throughout the hydroponic rack.</p>
                                <p>The controller used 2 magnetic float switches to ensure that there is adequate solution in the 14-gallon tank.  It then uses an 80 gallon per minuet pump to ensure adequate flow pressure from the tank, into the rack and then back into the tank again.  A 1-60 liter/min flow senser is then used to monitor the flow rate at which the solution in the tank is being pumped into the tank.</p>
                                <p>Like both the Environmental controller and the Hydroponic Solution Controller, the Reservoir management controller uses a 5.0v Arduino Nano and it is connected to the User Interface controller using the I2C/Two Wire communication protocol.</p>
                                <h6>User Interface Controller:</h6>
                                <p>The User interface controller adds simple but effective way for a user to interface with the 3 subsystems that are being used to manage the environment in the hydroponic rack.  The user interface system comprised of a Touch Screen UI, an Arduino Mega micro controller, and a small 32GB SD drive.  The SD drive as well as the three other Arduino Nano's are connected to the Arduino Mega via the I2C/two wire protocol.  The user interface uses an event driven method of sending and receiving data from the other three micro controllers.</p>
                                <p>When a user wishes to interact with a particular sub controller, they will send a command to that device from the Mega controller to the device in question using I2C protocol.  Upon receiving the command, the receiving Nano Controller will perform a process interrupt of any actions in the primary loop to process this command and send any required response back to the initiating system.  Once the Nano has finished the task that was assigned it will then resume the program that was being carried out before the interrupt happened.</p>
                                <p>The touch screen is the primary interface that a user must view data values on each sub-controller, as well as update configuration values on each sub controller.  The SD drive is used to save a copy of the users configuration to a small file so that if the system needs to be restarted it can get the last values that were sent to the sub-controller by the user.</p>
                                <h6>Hydroponic Rack:</h6>
                                <p>The Hydroponic Rack where plants, water, light, and cooling all come together.  The rack is sectioned out into three growing shelves.  Each shelve can accommodate up to 8 hydroponic pots separated into two single track rows. 24v Red/Blue LED grow lights are attached to the ceiling of each shelf to deliver optimal light saturation to the plants growing below.  The whole system is connected to a 14-gallon water storage tank and user an 80 GPM water pump to deliver water to the plants on the shelves.   Cooling is Provided by 2 12v fans that are attached to the ends of the shelves.  Hydroponic pH Up and Down Solution is held in two 1000 milliliter storage tanks and their delivery mechanism utilize one 12v dosage pump each.</p>
                                <p>Water is pumped up from to the top of the rack by the 80 GPM pump.  From there, the nutrient rich water then work its way down through each shelf in the rack before it is emptied back into the main tank to be cycled back through the rack system again.</p>
                            </div>
                            <div className="col-12 col-md-3 offset-md-1 meta">
                                <div className="tags">
                                    <span className="meta-heading">Tags</span>
                                    <Link to="/hydroponics/p2">Prototype 2</Link>
                                    <Link to="/hydroponics/p3">Prototype 3 (In development)</Link>
                                    <a target="_blank" href="https://store.arduino.cc/usa/nano-every-with-headers">Arduino Nano</a>
                                    <a target="_blank" href="https://store.arduino.cc/usa/mega-2560-r3">Arduino MEGA 2560 Rev3</a>
                                    <span className="meta-heading">Date</span><span>09-06-2020 to 11-12-2020</span>
                                </div>
                            </div>
                        </div>
                        <div className="more-projects">
                            <h3 className="text-center">Photo Gallary</h3>
                            <div className="row gallery">
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/IMG_0121.JPG">
                                            <img class="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/IMG_0121.JPG"></img>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/RWAF2403.JPG">
                                            <img className="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/RWAF2403.JPG"></img>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/IMG_0118.JPG">
                                            <img className="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/IMG_0118.JPG"></img>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3">
                                    <div class="item">
                                        <a target="_blank" href="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/IMG_0129.JPG">
                                            <img class="img-fluid scale-on-hover" src="https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/HydroLab/IMG_0129.JPG"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default withRouter(Prototype1);