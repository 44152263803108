import React from 'react';
import { withRouter } from "react-router-dom";
import { Footer, Navigation } from "../_components";

function Mark_CV() {
    return (
        <div className="HydroPageV1">
            <Navigation />
            <main className="page cv-page">
                <section className="portfolio-block block-intro border-bottom">
                    <div className="container">
                        <div className="avatar" style={{ backgroundImage: "url(https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/avatars/MarkPhotoforRSA.png)" }}></div>
                        <div className="about-me">
                            <p>Hello! I am <strong>Mark Salter</strong>.
                                A collaborative, renaissance-styled leader who gets to know individuals personally and typically extracts the best from everyone, as attested to by many who are surprise at achievements that rise above their expectations. A diplomatic, empowering leader who encourages people to make decisions autonomously. Hold regular one-on-one meetings and ensure there are no barriers to communication.  Always give serious consideration to team member critiques, acting on ideas most promising for overall betterment. Give credit where due and take blame for team blunders. Committed to doing that which is in the best interest of the organization.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="portfolio-block cv">
                    <div className="container">
                        <div className="work-experience group">
                            <div className="heading">
                                <h2 className="text-center">Work Experience</h2>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Head of Technology</h3>
                                        <h4 className="organization">GRID NEWS</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">08/2021 - present</span></div>
                                </div>
                                <p className="text-muted">Provide strong <b>technical leadership and strategy</b> to a media startup so that it can successfully launch a new media brand on time. Manage third party vendor relationships that are being used to provide technical support for our product and newsroom. Manage multiple development teams to ensure that we were able to deliver a high-quality product while also meeting our launch deadline.  Mentored the Product Delivery team (Product, engineering, Quality Assurance) in Test driven practices so that we could maintain a high degree of quality and reliability into a product delivery pipeline.  Mentored the product delivery team on adopting agile process to streamline the product planning and execution. Work with the leadership team to implement cyber security and data protection best practices throughout the company</p>
                                <h3>Key Management Achievements</h3>
                                <ul>
                                    <li>Successfully launched a new media brand on time across Web, iOS, and Android platforms.</li>
                                    <li><a href="https://grid.news">Grid News website</a></li>
                                    <li>Built out technical teams from scratch to produce high quality applications for Web, iOS, and Android platforms</li>
                                    <li>Developed a Test-Driven product delivery lifecycle that ensured that we delivered high quality features and services to our end users.</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Technical Product Manager</h3>
                                        <h4 className="organization">THE MEDIA TRUST INC.</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">03/2020 - 06/2020</span></div>
                                </div>
                                <p className="text-muted">Identify new product and business opportunities that conform to the company’s overall mission and vision. Monitor expert subject matter in the public domain, validate new product and business opportunities to ensure that they will return a positive return on the investment needed to produce them, and ensure that they fit within the company’s mission and vision.  Validate implementation plans and work with all relative stakeholders to bring the opportunities to market.  Monitor market developments to ensure that the company is ready to meet current and future needs of our customers.  Collaborate with the various stakeholders in sales and marketing to ensure that our products are being properly positioned.  Provide the overall <b>Technical Direction</b> of the company to all relative stakeholders in product and engineering in order to ensure that the company is building our product in a way that will meet our customers’ expectations.</p>
                                <h3>Key Management Achievements</h3>
                                <ul>
                                    <li>Developed our companies risk scoring metrics that we could use to calculate the level of risk exposure that a user could experience when interacting with a webpage.  These risk scores allowed us to win deals with several big name e-commerce retails as well as a large insurance company that wants to use it in helping to underwrite their customers’ websites.</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>VP of Application Development & Website Security</h3>
                                        <h4 className="organization">THE MEDIA TRUST INC.</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">06/2018 - 03/2020</span></div>
                                </div>
                                <p className="text-muted">Transitioned from coaching and mentoring technical employees to leading new strategic product implementations. Grew organization while implementing front-end development and architectural processes for new UI as <b>SCRUM master</b>, and leading multiple product development teams striving to meet all customer requirements. Technical Product Manager for Digital Vendor Risk Management Platform, Website Security Scanning platform, Website Performance, and Web Content Blocking Products. As a dedicated <b>leadership team member</b>, help formulate the company vision, strategies, and identify market growth opportunities.</p>
                                <h3>Key Management Achievements</h3>
                                <ul>
                                    <li>Promoted to report directly to the CEO as website security subject matter expert (SME)</li>
                                    <li>Designed business case and requirements for new, cutting-edge AWS-based product to capture publisher market; delivered entire project in three months resulting in new revenue stream profitable within nine months; expanded to European market.</li>
                                    <li>Rescued biggest account and turned $250K client into $750K client in only six months; on track to close $1M in 2019</li>
                                    <li>Built SCRUM teams around key product lines within the company to enhance productivity; increased transparency and communication with cross-functional teams including management, developers, Sales, Client Operations, and quality assurance testers.</li>
                                    <li>Developed and managed partnerships with local contracting companies to create elastic capacity within the security and technology teams; cut project development lifecycle time by 50%.</li>
                                    <li>Oversaw re-architecting of flagship SaaS platform into a highly decoupled enterprise level n-tier platform.</li>
                                    <li>Instituted quality assurance measures and testing guides to improve code quality in production.</li>
                                    <li>Grew technical team from five to 25-person organization centered around three core architecture teams: Platform development, Business integrations, and Data Collections and Analysis.</li>
                                    <li>Led comprehensive cyber security risk assessment of entire business; turned assessment into action plan to improve overall security posture to navigate future challenges.</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Manager, Front End Development & Cyber Security</h3>
                                        <h4 className="organization">THE MEDIA TRUST INC.</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">12/2013 - 06/2018</span></div>
                                </div>
                                <p className="text-muted">Currently I have several roles here at The Media Trust.  My Primary Role is that of the Front-end development manager and my duties in this role include the following; being the SCRUM master for my multiple development teams, overseeing the application architecture for our new UI, and work with the for Product Development Team to flush out new requirements that are being asked for by our many customers.  In addition to managing the UI team, I also perform tier two support for our malware research team helping them to investigate new forms of malware attacks to see how it is getting delivered and how we can better detect it within our system. My last set of duties are being an active member of the companies Leadership team, in which I help provide vision and strategy to helping the company grow as an organization</p>
                                <h3>Security Achievements</h3>
                                <ul>
                                    <li>Presented at the RSA 2017 Security Conference on the risks of shadow IT and third party code.</li>
                                    <li>Presented at Blackhat 2017 security conference on the risks of blindly using third party code on websites today.</li>
                                    <li>Performed forensic research into where and how many Midsized e-commerce platforms were being hacked and allowing a malicious actor to steal users credit card information from under the users nose.  This research was then presented at RSA and Blackhat conferences as demonstrations on how a Man in the Middle attack can still happen even when the website uses SSL certification.  I also worked with the malware annalists to help them crack the latest forms of code obfuscation techniques that hackers use to hide their malicious code. </li>
                                </ul>
                                <h3>Management Achievements</h3>
                                <ul>
                                    <li>Built up a front-end development team from scratch transforming it form a single team into a multiple teams that could focus on better on our multiple product initiatives.</li>
                                    <li>Implemented SCRUM across all of my product development teams to promote better communication between product managers, developers, and quality assurance testers</li>
                                    <li>Architected many innovative security features into out product platform so that it could resound and defend itself against unsolicited penetration testers.  This included the Re-built our account security model and the implementation of a 2 factor authentication system.  It also include input testing that would lock and kill a session if it detected sql, JavaScript and file system injection attacks. We also incorporated honey pot traps and BOT detection that we could then use to allow our system to defend itself from a cyber attack.</li>
                                    <li>Successfully managed the relationship between our subcontracted user experience company and the product managers within The Media Trust to make sure we were able to keep the new redesign of the application on schedule and on budget</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Lead Developer & Architect</h3>
                                        <h4 className="organization">COSTAR GROUP INC.</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">12/2011 - 12/2013</span></div>
                                </div>
                                <p className="text-muted">As a lead software engineer on the corporate systems team at CoStar Group, I am responsible for development of the company’s data collection platforms.  These systems include there in house CRM and property management system called Enterprise, as well as developing automated services that can be used to collect and upload data that has been provided by third party vendors.</p>
                                <h3>Engineering Achievements/Activities</h3>
                                <ul>
                                    <li>Principle developer for the company’s new Matching Services platform that will allow us to better match data that is brought into our system from external sources with data that is currently residing within our database.  The system is designed to be scalable, and extendable, so that we can easily upgrade and improve the reliability of the system as throughout the life of the system.</li>
                                    <li>Lead developer for the company’s new asynchronous and horizontally scalable data loading platform that can process data from multiple external sources efficiently upload the data into our core databases.</li>
                                    <li>Leading an initiative to consolidate the current researcher platforms business logic out of the database and frontend client and into a scalable web service tier.</li>
                                    <li>Developed a multi-threaded batch uploading service to allow us to automatically import mortgage-refinancing data into our data warehouse.  This data is then used by our various products to allow our customers to make more informed decisions when it comes to buying and leasing commercial properties.</li>
                                    <li>Developed a horizontally scalable attachment retrieval service to allow up to import loan and deed documents form a vender site and upload them into our data warehouse.  Then documents can then be used by our customers as part of the decision making process when it comes to buying or leasing commercial real-estate. </li>
                                    <li>Leading a workshop group on improving code quality through the use of clean coding practices.</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Director of Applications Development</h3>
                                        <h4 className="organization">EPALS INC.</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">05/2010 - 10/2011</span></div>
                                </div>
                                <p className="text-muted">As Director of Application Development at ePals, I was responsible for day-to-day management of ePals platform development team responsible for developing and maintaining there next generation web 2.0 Social learning applications such as LearningSpace, as well as the International Baccalaureate Virtual Community.  In addition to the management of the platform team I was also responsible for the architectural design of the system and making sure that the platform was being built to meet the needs of the applications that it would need to support.</p>
                                <h3>Engineering Achievements/Activities</h3>
                                <ul>
                                    <li>Launched the International Baccalaureate Virtual Community site (IBVC)</li>
                                    <ul>
                                        <li>Was able to make the necessary corrections in the management of the IBVC development team to help turn around major project that was struggling to meet the expectations of both the product manager, and the customer.  This in turn led to a successful launch of a new virtual learning community site for the IB.  As a result of the successful launch ePals was able to win additional business to build out and host their flagship product the IB OCC/WRC</li>
                                    </ul>
                                    <li>Worked with Product management, using an iterative process, to turn product requirements into actionable user stories that developers could then create development tasks against.</li>
                                    <ul>
                                        <li>This allowed my developers to deliver application features of better quality, in a shorter amount of time.  It also allowed us to develop better estimates of when features would be realest to our production environments.</li>
                                    </ul>
                                    <li>Worked with Project Management to implement SCRUM practices within my development team.</li>
                                    <ul>
                                        <li>This allowed me to improve the lines of communications between product management, my development team, QA, and the systems operations teams.  This also allowed me to map out and utilize my development recourses more efficiently, between the various projects that I have going.  It also allowed me to give more realistic delivery dates to senior management and our customers.</li>
                                    </ul>
                                    <li>Mentored my development team in using architectural design patterns and best practices so solve complex development issues.</li>
                                    <ul>
                                        <li>This in turn led to the development of more maintainable, and scalable web applications, and web services being delivered into our production environments.</li>
                                    </ul>
                                    <li>Implemented test driven development practices within my development team to help us deliver better quality code to our QA and Production environments.</li>
                                    <ul>
                                        <li>This allowed my team to find and fix more defects during the development process, thus improving the quality of the code that was being sent to QA.  This also resulted in fewer testing cycles for code to be released to production servers.</li>
                                    </ul>
                                    <li>Implemented better build and configuration management practices to allow us to improve and streamline our build and deployment processes to our various development and testing environments.</li>
                                    <ul>
                                        <li>This resulted in more stable build packages being released into our production environment as well as a quicker and more reliable way of updating the production servers with the latest version of our code.</li>
                                        <li>All web applications that were developed on top of this platform were developed using Microsoft MVC 3, as well as Java Spring 3.</li>
                                    </ul>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Principal Software Engineer for Slate Magazine/Slate.com</h3>
                                        <h4 className="organization">WASHINGTON POST.NEWSWEEK INTERACTIVE</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">06/2008 - 05/2010</span></div>
                                </div>
                                <p className="text-muted">After the principal engineer of Slate left, I stepped up to lead the Slate development team in daily support of Slate operations.  My other duties at Slate include being the principle web applications Architect for Slate Magazine, being responsible for developing, robust and scalable web applications that can handle the ever increasing traffic volume that is being delivered to the Slate.com site.  I am also the primary contact for both the Slate editorial staff when it relates to CMS issues that they might be having, and for the Washington Post Infrastructure team, when it comes to server related issues for the Slate.com website.</p>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Lead .Net Engineer/GIS Lead Engineer</h3>
                                        <h4 className="organization">METROSTAR SYSTEMS, INC</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">06/2006 - 05/2008</span></div>
                                </div>
                                <p className="text-muted">Lead Software Engineer of a fast growing software solutions company operating in the commercial and government software solution space. As Lead GIS developer, current responsibilities include designing and developing geographical information systems for the federal government.  These systems include: Web based front ends currently using Microsoft Virtual Earth, AJAX, and custom web services; back end data model development using SQL Server 2005; Geo-coding services using CLR Triggers, MSMQ message queuing, and the MapPoint Web Service. As lead .Net Engineer, current responsibilities include, providing development and integration support for the various development groups throughout the company, training junior developers in the latest coding patterns and practices.</p>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Software Developer</h3>
                                        <h4 className="organization">DISTRIBUTED SOLUTIONS INC.</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">07/2005 - 08/2006</span></div>
                                </div>
                                <p className="text-muted">Senior Developer of a growing software company specializing in document generation and tracking products for the federal government.  Primary responsible include design and development of new functionality within our existing “Automated Acquisition Management System” (AAMS 6) application, Research and Development of new products, integration of third party tools into the core products, integrations between third party business systems, Product configuration management, and product Maintenance Management.</p>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Software Developer</h3>
                                        <h4 className="organization">INSIGHT, INCORPORATED.</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">08/2001 - 07/2005</span></div>
                                </div>
                                <p className="text-muted">Leader of a small development team of a growing software firm for company’s flagship supply chain optimization software, SAILS (Strategic Analysis of Inbound Logistic Systems).   Responsible for applications coding, GUI and database design, beta testing and installation package creation.  Assistant to SAILS Marketing Product Manager in for development policy, defects tracking and organization of product support group.  Authored user manuals, provided support for 25 clients, and conducted product training.</p>
                            </div>
                        </div>
                        <div className="education group">
                            <div className="heading">
                                <h2 className="text-center">Education</h2>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Bachelor of Science in Biology</h3>
                                        <h4 className="organization">George Mason University</h4>
                                    </div>
                                    <div className="col-6"><span className="period">08/1995 - 05/2000</span></div>
                                </div>
                                <ul>
                                    <li>Awarded First Place in University Engineering Fair for Prototype Search and Rescue Robot</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>CERTIFICATIONS</h3>
                                    </div>
                                </div>
                                <ul>
                                    <li>SCRUM certified through the Scrum Alliance</li>
                                </ul>
                            </div>
                        </div>
                        <div className="group">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="skills portfolio-info-card">
                                        <h2>Technical Skills</h2>
                                        <h3>Microsoft Frameworks 4.0/4.5/Core 3.1</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '95%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                        <h3>C#</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ width: '95%' }}><span class="visually-hidden">90%</span></div>
                                        </div>
                                        <h3>C/C++</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '70%' }}><span class="visually-hidden">100%</span></div>
                                        </div>
                                        <h3>Postgres/Microsoft Sql Server</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '85%' }}><span class="visually-hidden">90%</span></div>
                                        </div>
                                        <h3>JavaScript</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '80%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                        <h3>React</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '50%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                        <h3>PHP 5.x</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                        <h3>Amazon Web Services</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '70%' }}><span class="visually-hidden">90%</span></div>
                                        </div>
                                        <h3>Arduino Micro Controllers</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}><span class="visually-hidden">90%</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="skills portfolio-info-card">
                                        <h2>Soft Skills</h2>
                                        <h3>Strategic Direction/Leadership</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '90%' }}><span class="visually-hidden">100%</span></div>
                                        </div>
                                        <h3>New Products/Integrations</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}><span class="visually-hidden">90%</span></div>
                                        </div>
                                        <h3>Problem Solving & Management</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                        <h3>Cross-Team Collaboration</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                        <h3>Team Development & Culture</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                        <h3>Product Management</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                        <h3>Product Launch/Go-To Market</h3>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '40%' }}><span class="visually-hidden">80%</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="contact-info portfolio-info-card">
                                        <h2>Contact Info</h2>
                                        <div className="row">
                                            <div className="col-1"><i className="icon ion-person icon"></i></div>
                                            <div className="col-9"><span>Mark Salter</span></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1"><i className="icon ion-ios-telephone icon"></i></div>
                                            <div className="col-9"><span>+1(703)-867-3865</span></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1"><i className="icon ion-at icon"></i></div>
                                            <div className="col-9"><span>mark_salter52@hotmail.com</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default withRouter(Mark_CV);