// JavaScript source code
import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { Footer, Navigation } from "../_components";

function Prototype3() {
    return (
        <div className="Prototype1">
            <Navigation />
            <main className="page project-page">
                <section className="portfolio-block project">
                    <div className="container">
                        <div className="heading">
                            <h2>Remote Urban Hydroponics System (RUHS) Prototype 3</h2>
                        </div>
                        <div className="image" style={{ backgroundImage: "url(https://elasticbeanstalk-us-east-2-057753570502.s3.us-east-2.amazonaws.com/Bio-Systems-Management-UI/img/publicsite/stockimgs/hydroponic-farm-blog-1030x651.png)" }}></div>
                        <div className="row">
                            <div className="col-12 col-md-6 offset-md-1 info">
                                <h3>Overview</h3>
                                <p>Prototype 3 is currently in development and its projected to be ready for operational trials by mid-August.  The Goal of prototype 3 is to start proving out the concept of a more scalable, modular, and configurable architecture.  The prototype will also attempt to fix lingering mechanical issues that came up during the operational trials of both Prototypes 1 and 2.</p>
                                <p>To accomplish these goals, we will seek to further decentralize the micro-controller subsystems and allow them to be placed closer to things that they are intended to control.  We will also be we re-designing the whole Hydroponic platform so it can become more modular in its design.  Both goals will allow us more easily to scale up systems as needed. The platform will also become more modular so that it will become more easy to swap in upgraded systems as they become available.</p>
                                <p>Finally, changes will also be made to the platforms data collection processes so that the data being transmitted from the microcontrollers can be stored in a time based format allowing us to provide the users more analytical insights into how their growing environments are performing.</p>
                             </div>
                            <div className="col-12 col-md-3 offset-md-1 meta">
                                <div className="tags">
                                    <span className="meta-heading">Tags</span>
                                    <Link to="/hydroponics/p1">Prototype 1</Link>
                                    <Link to="/hydroponics/p2">Prototype 2</Link>
                                    <a target="_blank" href="https://store.arduino.cc/usa/nano-33-iot-with-headers">Arduino Nano 33 IoT</a>
                                    <a target="_blank" href="https://atlas-scientific.com/embedded-solutions/ezo-ph-circuit/">Atlas Scientific EZO pH Circuit</a>
                                    <a target="_blank" href="https://atlas-scientific.com/embedded-solutions/ezo-conductivity-circuit/">Atlas Scientific EZO Conductivity Circuit</a>
                                    <a target="_blank" href="https://atlas-scientific.com/embedded-solutions/ezo-rtd-temperature-circuit/">Atlas Scientific EZO RTD Circuit</a>
                                    <span className="meta-heading">Date</span><span>04-21-2021 to Present</span>
                                </div>
                            </div>
                        </div>
                        <div className="more-projects">
                            
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default withRouter(Prototype3);